<template>
	<div class="auditWaybillReview">
		<jy-dialog
			type="form"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_form"
			:width="global.dialogWidth"
			append-to-body
		>
			<div>
				<el-form :model="diaform" ref="dialogForm" :disabled="isCheck" :label-width="formLabelWidth">
					<el-form-item label="审核结果:" prop="auditState" :label-width="formLabelWidth">
						<el-radio v-model="diaform.auditState" label="2">通过</el-radio>
						<el-radio v-model="diaform.auditState" label="3">不通过</el-radio>
					</el-form-item>
					<el-form-item label="备注:" prop="auditRemark">
						<el-input v-model="diaform.auditRemark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<div v-if="!isCheck">
					<el-button @click="cancel">取 消</el-button>
					<el-button type="primary" @click="save('dialogForm')">确 定</el-button>
				</div>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			type: "",
			isCheck: false,
			title: "",
			props: {
				children: "children",
				label: "text"
			},
			diaform: {
				auditState: "2",
				auditRemark: ""
			},
			selectData: "",
			dialogVisible: false,
			formLabelWidth: "120px"
		};
	},
	components: {},
	activated() {},
	created() {},
	watch: {
		type: function (val) {
			let t = "";
			let isCheck = false;
			switch (val) {
				case "batch":
					t = "营运班次批量审核";
					break;
				case "single":
					t = "营运班次审核";
					break;
				case "view":
					t = "查看营运班次审核";
					isCheck = true;
					break;
				default:
					t = "营运班次批量审核";
			}
			this.isCheck = isCheck;
			this.title = t;
		},
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("dialogForm");
			}
		}
	},
	methods: {
		init(option, type) {
			this.type = type ? type : "batch";
			this.dialogVisible = true;
			if (option) {
				this.$nextTick(() => {
					this.selectData = JSON.parse(JSON.stringify(option));
				});
			}
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		save(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let option = {};
					let url = "";
					if (this.type == "batch") {
						url = "/WaybillVerity/batchVerify";
						let ids = [];
						this.selectData.forEach(item => {
							ids.push(item.waybillId);
						});
						option = {
							verity: this.diaform.auditState,
							remark: this.diaform.auditRemark,
							waybillIds: ids.join(",")
						};
					} else {
						url = "/WaybillVerity/verify";
						option = {
							verity: this.diaform.auditState,
							remark: this.diaform.auditRemark,
							waybillId: this.selectData.waybillId
						};
					}

					this.$http.post(url, option).then(res => {
						this.$message({
							type: "success",
							message: res.msg
						});
						this.dialogVisible = false;
						this.$emit("updateList");
					});
				} else {
					return false;
				}
			});
		}
	}
};
</script>
